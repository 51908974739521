import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import gsap from "gsap"
import React, { useEffect } from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = ({ data }) => {
  const onClick = () =>
    document.querySelector(".register--overlay").classList.add("active")

  useEffect(() => {
    var num = 2
    var tl = gsap.timeline({ repeat: -1, delay: 6.5 })
    tl.to(".messages .message", {
      opacity: 0,
      delay: 2,
      duration: 0.5,
      onComplete: function () {
        document.querySelector(".line.active")?.classList.remove("active")
        document
          .querySelector(".line:nth-child(" + num + ")")
          ?.classList.add("active")
        num++
        if (num > 4) {
          num = 1
        }
      },
    })
    tl.to(".messages .message", { opacity: 1, duration: 0.5, delay: 0.1 })
  })

  return (
    <Layout>
      <div className="h-100vha flex flex-wrap">
        <div className="title-area max-900 text-center mla mra mta m-p20 z-2 pos-rel white">
          <h2 className="m0">
            An exclusive opportunity coming to the coveted kangaroo point
            peninsula
          </h2>
          <div className="messages mt40 uppercase">
            <div className="message">
              <p className="m0 line active">Be one of seven</p>
              <p className="m0 line">Brisbane's box seat address</p>
              <p className="m0 line">350 - 630 sqm full floor residences</p>
              <p className="m0 line">Construction Underway, coming Q1 2025</p>
            </div>
          </div>
        </div>
        <div className="w-100 mta mb0 text-center pb20 pos-rel z-2 white">
          <p
            className="button m0 mb40 m-m0 white"
            onClick={onClick}
            role="presentation"
          >
            Register
          </p>
        </div>
        <GatsbyImage
          image={data.page.image?.gatsbyImageData}
          alt="Background"
          className="bg-image m-hide"
        />
        <GatsbyImage
          image={data.page.mobileImage?.gatsbyImageData}
          alt="Background"
          className="bg-image m-show m-filter"
        />
      </div>
    </Layout>
  )
}

export const Head = () => <Seo title="Home" />

export default IndexPage

export const datoQuery = graphql`
  query {
    page: datoCmsHomepage {
      image {
        gatsbyImageData
      }
      mobileImage {
        gatsbyImageData
      }
    }
  }
`
